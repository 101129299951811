import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

initializeApp(firebaseConfig);
const db = getFirestore();

export const authenticateAnonymously = () => {
  const auth = getAuth();
  return signInAnonymously(auth);
};

export const getPrices = () => {
  const pricesDocRef = doc(db, "prices", "USD");
  return getDoc(pricesDocRef);
};

export const checkUser = async (app, email) => {
  const headers = {
    "Content-Type": "application/json",
    app,
  };
  const userType = await axios.post(
    "https://admin.codingx.app/users/status",
    {
      email,
    },
    { headers }
  );
  return userType.data.data.proStatus;
};

export const adminLogin = async (username, password) => {
  const res = await axios.post(
    "https://admin.codingx.app/login",
    {
      username,
      password,
    }
  );
  if (res.data.data.login) {
    return true;
  }
  return false;
};

export const makeUserPro = async (email, app, promoDetails) => {
  const headers = {
    "Content-Type": "application/json",
    app,
  };
  await axios.post(
    "https://admin.codingx.app/users",
    {
      email,
      promoDetails,
    },
    { headers }
  );
};

export const removeUserPro = async (email, app) => {
  const headers = {
    "Content-Type": "application/json",
    app,
  };
  await axios.put(
    "https://admin.codingx.app/users",
    {
      email,
    },
    { headers }
  );
};