export const reducer = (state, action) => {
    switch (action.type) {
      default:
        return { ...state, ...action };
    }
  };
  
  export const initialState = {
    login: false,
  };
  