import './App.css';
import * as React from 'react';
import { AppStateContext } from './contexts/appContext';
import { Login } from './screens/Login';
import { AddUser } from './screens/AddUser';
import { SelectMode } from './screens/SelectMode';
import { RemoveUser } from './screens/RemoveUser';

function App() {
  const [state] = React.useContext(AppStateContext);
  return (
    <>
      {state.mode === "add" ? <AddUser /> : state.mode === "remove" ? <RemoveUser /> : state.login ? <SelectMode /> : <Login />}
    </>
  );
}

export default App;
